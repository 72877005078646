import { nanoid } from "nanoid";
import styled from "styled-components";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiInputBase from "@material-ui/core/InputBase";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiBox from "@material-ui/core/Box";
import MuiDialog from "@material-ui/core/Dialog";
import MuiInputAdornment, {
  InputAdornmentProps as MuiInputAdornmentProps,
} from "@material-ui/core/InputAdornment";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@material-ui/core/Popover";
import TextField from "lib/components/TextField";
import Tag, { TagProps } from "lib/components/Tag";
import IconButton from "lib/components/IconButton";
import useKeyPress from "lib/hooks/useKeyPress";
import IconClose from "lib/assets/close.svg";
import IconCloseFullScreen from "@material-ui/icons/Close";
import IconArrowUp from "lib/assets/arrow-up.svg";
import IconArrowDown from "lib/assets/arrow-down.svg";
import IconSearch from "lib/assets/search.svg";
import IconCheck from "lib/assets/check.svg";
import { useTheme } from "lib/contexts/themeContext";
import { KeyboardKey } from "lib/enums";

const PopoverWrapper = styled(
  ({ small, ...props }: MuiPopoverProps & { small?: boolean }) => (
    <MuiPopover
      classes={{ paper: "select-popover-paper" }}
      {...props}
      PaperProps={{
        variant: small ? "elevation" : "outlined",
        classes: { elevation0: "select-popover-elev" },
      }}
    />
  ),
)`
  &&& .select-popover-paper {
    border-width: ${({ small }) => (small ? "0" : "1px")};
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 10px;
    margin-top: 0;
    width: ${({ anchorEl }) =>
      anchorEl && (anchorEl as HTMLElement).offsetWidth
        ? `${(anchorEl as HTMLElement).offsetWidth}px`
        : "auto"};
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &&& .select-popover-elev {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  }
`;

// Styled TextField component to disable underlying input element.
// We rely on InputAdornment component to render inputs
const SelectTextField = styled((props: MuiTextFieldProps) => (
  <TextField
    {...props}
    variant="filled"
    InputProps={{
      ...props.InputProps,
      classes: { inputAdornedStart: "select-input-adorned-start" },
    }}
  />
))`
  &&& {
    background-color: #ffffff;
    border-radius: 10px;

    input,
    textarea {
      cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
    }

    .input-base {
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .select-input-adorned-start {
      display: none;
    }

    // styling for the small variant
    ${({ size }) =>
      size === "small"
        ? `

    background-color: #F8F8F8;
    border-radius: 80px;

    && * {
      border: none;
    }

    && input {
      color: #999999;
      padding: 9px 0 9px 20px;
    }

    // chevron
    && svg path {
      fill: #999999;
    }

    `
        : ""};
  }
`;

// Represents a single input in a multiple select interface
const InputTag = styled((props: TagProps) => (
  <Tag {...props} icon={<IconClose />} />
))`
  &&& {
    margin-right: 10px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const InputAdornment = styled((props: MuiInputAdornmentProps) => (
  <MuiInputAdornment
    {...props}
    classes={{
      positionStart: "input-adornment-start",
      positionEnd: "input-adornment-end",
    }}
  />
))<{ multiple?: boolean }>`
  // needed to properly display multiple tag inputs
  &&& {
    &.input-adornment-start {
      flex-wrap: wrap;
      padding: 8px 10px 3px;
      height: auto;
      max-height: none;
      flex: 0 0 auto;
      width: 100%;
      box-sizing: border-box;
    }

    &.input-adornment-end {
      ${({ multiple }) => (multiple ? "padding-right: 14px" : null)}
    }
  }
`;

const List = styled(MuiList)<{ fullScreen?: boolean }>`
  &&& {
    ${({ fullScreen }) =>
      fullScreen
        ? "min-height: 0px; flex-grow: 1; flex-basis: 100%;"
        : "max-height: 300px;"}
    padding: 0;
    position: relative;
    overflow-y: auto;
  }
`;

const ListItem = styled((props) => (
  <MuiListItem
    classes={{
      selected: "select-list-item-selected",
      disabled: "select-list-item-disabled",
    }}
    {...props}
  />
))`
  &&& {
    :hover,
    :active,
    :focus {
      background-color: ${({ theme }) => theme.palette.blueGrey[100]};
    }
    .MuiListItemText-primary {
      font-size: 16px;
      ${({ light }) => (light ? "color:#999999;" : "")}
    }
  }

  &&&.select-list-item-selected {
    background-color: ${({ theme }) => theme.palette.blueGrey[100]};
  }

  &&&.select-list-item-disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.grey[100]};

    :hover {
      background: transparent;
    }
  }
`;

const ListItemSearch = styled(ListItem)`
  &&&& {
    background-color: white;
  }
`;

const FilterTextInput = styled(MuiInputBase)`
  &&& {
    font-size: 16px;
    line-height: 1.57;
  }
`;

interface BaseSelectProps {
  options: SelectOption[];
  label?: string;
  placeholder?: string | undefined;
  helperText?: string | false | undefined;
  popupRef?: React.RefObject<HTMLDivElement>;
  required?: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  /**
   * Set it to true, or false to explicitly
   * enable or disable the search input respectively.
   * Otherwise, search input is hidden for
   * options containing less than 5 menu items.
   *
   * @type {boolean}
   * @memberof BaseSelectProps
   */
  withSearch?: boolean;
  fullScreen?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  small?: boolean;
}

interface SingleSelectProps extends BaseSelectProps {
  value: SelectOptionValueType;
  onChange: (value: any) => void;
  multiple?: false;
  checklist?: false;
}

interface MultiSelectProps extends BaseSelectProps {
  value: SelectOptionValueType[];
  onChange: (value: any) => void;
  multiple: true;
  checklist?: boolean;
}

type SelectProps = SingleSelectProps | MultiSelectProps;

export default function Select(props: SelectProps) {
  const theme = useTheme();
  const {
    value: fieldValue = props.multiple ? [] : "",
    label: fieldLabel,
    multiple,
    onChange,
    options,
    placeholder,
    helperText,
    error,
    required,
    withSearch,
    checklist,
    className,
    disabled,
    fullScreen,
    onClick,
    onClose,
    small,
    ...rest
  } = props;

  const uniqueKey = nanoid();
  const [isListOpen, setIsListOpen] = useState(false);
  const [filteredText, setFilteredText] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [keyIndex, setKeyIndex] = useState<number | undefined>(undefined);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const escPress = useKeyPress(KeyboardKey.Esc);
  const escapePress = useKeyPress(KeyboardKey.Escape);
  const enterPress = useKeyPress(KeyboardKey.Enter);
  const arrowUpPress = useKeyPress(KeyboardKey.ArrowUp);
  const arrowDownPress = useKeyPress(KeyboardKey.ArrowDown);

  const setValue = useCallback(
    (option: SelectOption) => {
      if (multiple) {
        const updateValue = Array.isArray(fieldValue)
          ? fieldValue.slice(0)
          : [];
        updateValue.push(option.value);
        onChange(updateValue);
        setKeyIndex(undefined);
        setFilteredText("");
      } else {
        onChange(option.value);
      }
    },
    [fieldValue, multiple, onChange],
  );

  // filter options by filter text
  const selectableMenu = useMemo(
    () =>
      options.filter(
        ({ label, value }) =>
          label.toString().toLowerCase().indexOf(filteredText.toLowerCase()) >
            -1 &&
          // Show option when the following expression evaluates to true
          // 1. value is not already selected
          // 2. single value interface
          // 3. multiple value interface w/ checklist enabled
          ((multiple &&
            Array.isArray(fieldValue) &&
            !fieldValue.includes(value)) ||
            !multiple ||
            (multiple && checklist)),
      ),
    [checklist, fieldValue, filteredText, multiple, options],
  );

  // Closes the list menu
  const closeList = useCallback(() => {
    setFilteredText("");
    setKeyIndex(undefined);

    // Sets blur on input element
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  const handleOnCloseList = useCallback(() => {
    setIsListOpen(false);
    onClose?.();
  }, [onClose]);

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (isListOpen) {
      // automatically focus on input on edit
      setFocusInput();
    } else {
      // automatically blur on input on cancel edit
      closeList();
    }
  }, [closeList, isListOpen]);

  // Detects keyboard key presses
  useEffect(() => {
    if (isListOpen) {
      if (escPress || escapePress) {
        // Escape key is intercepted by Popover component,
        // so we instead use its onClose method to close the Popover
        // do nothing
      } else if (enterPress) {
        if (keyIndex !== undefined) {
          setFilteredText("");
          const option = selectableMenu.find((_, i) => keyIndex === i);
          if (option) {
            setValue(option);
            handleOnCloseList();
          }
        }
      } else if (arrowUpPress) {
        if (keyIndex !== undefined && keyIndex !== 0) {
          return setKeyIndex(keyIndex - 1);
        }
        return setKeyIndex(0);
      } else if (arrowDownPress) {
        if (keyIndex === undefined) {
          return setKeyIndex(0);
        }
        return setKeyIndex(keyIndex + 1);
      }
    }
  }, [
    isListOpen,
    keyIndex,
    selectableMenu,
    setValue,
    escPress,
    escapePress,
    enterPress,
    arrowUpPress,
    arrowDownPress,
    handleOnCloseList,
  ]);

  /* Handlers */
  // Pass variable to setValue
  // Because MuiListItem's onClick event is not allowed function with variable
  const handleOnMenuItemClick =
    (option: SelectOption) => (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!checklist) {
        setValue(option);
        handleOnCloseList();
      } else {
        // is checklist
        if (existsInSelectedValueArray(option)) {
          removeValue(option);
        } else {
          setValue(option);
        }
      }
    };

  // Handles when user decides to change value
  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredText(event.target.value);
  };

  const handleOnClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    // if target is input filter not be blurred
    if (
      (e.target as HTMLInputElement).name !== `input-select-filter-${uniqueKey}`
    ) {
      handleOnCloseList();
    }
  };

  // Sets focus on search input element
  const setFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOnInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    onClick?.();
    setAnchorEl(event.currentTarget);
    setIsListOpen(!!event.currentTarget);
  };

  // Makes a handler function that removes a single element from a mutiple select interface
  const makeRemoveSingleValue = (optionToRemove: SelectOption) => () => {
    removeValue(optionToRemove);
  };

  const removeValue = (optionToRemove: SelectOption) => {
    if (multiple) {
      const updateValue = Array.isArray(fieldValue)
        ? fieldValue.filter((option) => option !== optionToRemove.value)
        : [];
      onChange(updateValue);
    } else {
      // shouldn't get here
      onChange("");
    }
  };

  // checks whether a specific option exists in the selected options
  const existsInSelectedValueArray = (optionToCheck: SelectOption) => {
    return Boolean(
      selectedValueArray.find((option) => option.value === optionToCheck.value),
    );
  };

  let selectedValueString = "";
  let selectedValueArray: SelectOption[] = [];
  if (multiple && Array.isArray(fieldValue)) {
    // An option's human-readable label may be different from its value.
    // But we only have a list of actual values, so it needs to filter options which included in its value
    selectedValueArray = options
      .filter(({ value }) => fieldValue.indexOf(value) > -1)
      .sort(
        (a, b) => fieldValue.indexOf(a.value) - fieldValue.indexOf(b.value),
      );
  } else {
    const option = options.find(({ value }) => value === fieldValue);
    selectedValueString = option?.label || "";
  }

  const hasValue = selectedValueString !== "" || selectedValueArray.length > 0;

  return (
    <MuiClickAwayListener onClickAway={handleOnClickAway}>
      <MuiFormControl fullWidth variant="outlined" className={className}>
        {fieldLabel && (
          <MuiInputLabel error={error} variant="outlined" required={required}>
            {fieldLabel}
          </MuiInputLabel>
        )}
        <SelectTextField
          name={`input-select-value-${uniqueKey}`}
          size={small ? "small" : undefined}
          onClick={handleOnInputClick}
          placeholder={
            checklist && selectedValueArray.length > 0
              ? `${selectedValueArray.length} Selected`
              : !hasValue
              ? placeholder
              : ""
          }
          inputRef={textFieldRef}
          value={hasValue ? selectedValueString : ""}
          helperText={helperText}
          error={error}
          multiline={multiple}
          variant={small ? "filled" : "outlined"}
          InputProps={{
            startAdornment: multiple &&
              !checklist &&
              selectedValueArray.length > 0 && (
                <InputAdornment position="start">
                  {selectedValueArray.map((option: SelectOption) => (
                    <InputTag
                      key={`${option.label}-${option.key}`}
                      label={option.label}
                      size={"small"}
                      onIconClick={
                        disabled ? undefined : makeRemoveSingleValue(option)
                      }
                    />
                  ))}
                </InputAdornment>
              ),
            endAdornment: (!multiple || checklist) && !disabled && (
              <InputAdornment position="end" multiple={multiple}>
                {isListOpen ? <IconArrowUp /> : <IconArrowDown />}
              </InputAdornment>
            ),
          }}
          inputProps={{
            readOnly: true,
          }}
          disabled={disabled}
        />
        <PopoverWrapper
          open={isListOpen && !fullScreen && !disabled}
          anchorEl={isListOpen ? anchorEl : undefined}
          small={small}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableRestoreFocus
          elevation={0}
          onClose={handleOnCloseList}
        >
          {/* only display search input when option length is bigger than 5
            also display when it is explicitly told to or not to */}
          {((withSearch && !small) ||
            (withSearch !== false && options.length > 5)) && (
            <ListItemSearch divider disabled>
              <MuiInputAdornment position="start">
                <IconSearch />
              </MuiInputAdornment>
              <FilterTextInput
                name={`input-select-filter-${uniqueKey}`}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                autoSave="off"
                placeholder="Search"
                fullWidth
                value={filteredText}
                onChange={handleOnInputChange}
                inputRef={inputRef}
                autoFocus={isListOpen}
                {...rest}
              />
            </ListItemSearch>
          )}
          <List>
            {selectableMenu.length === 0 ? (
              <ListItem button disabled light={small}>
                There are no available items
              </ListItem>
            ) : (
              selectableMenu.map((option, index) => (
                <ListItem
                  key={option.key}
                  onClick={handleOnMenuItemClick(option)}
                  selected={keyIndex === index}
                  button
                  light={small}
                >
                  <MuiListItemText
                    primary={option.label}
                    primaryTypographyProps={{
                      color: option.primary ? "primary" : "inherit",
                    }}
                  />
                  {checklist && (
                    <IconButton edge="end" disabled>
                      {existsInSelectedValueArray(option) ? (
                        <IconCheck fill={theme.palette.primary.main} />
                      ) : null}
                    </IconButton>
                  )}
                </ListItem>
              ))
            )}
          </List>
        </PopoverWrapper>
        {/* fullScreen select */}
        <MuiDialog
          open={isListOpen && !!fullScreen && !disabled}
          onClose={handleOnCloseList}
          fullScreen
        >
          <MuiBox display="flex" flexDirection="column" height="100%">
            {/* top section */}
            <MuiBox
              px={4}
              py={0}
              height="70px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MuiBox
                position="absolute"
                display="flex"
                left="0"
                py="21px"
                px={4}
                zIndex={0}
              >
                <IconCloseFullScreen onClick={handleOnCloseList} />
              </MuiBox>
            </MuiBox>
            {(withSearch || (withSearch !== false && options.length > 5)) && (
              <ListItemSearch divider disabled>
                <MuiInputAdornment position="start">
                  <IconSearch />
                </MuiInputAdornment>
                <FilterTextInput
                  name={`input-select-filter-${uniqueKey}`}
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoSave="off"
                  placeholder="Search"
                  fullWidth
                  value={filteredText}
                  onChange={handleOnInputChange}
                  inputRef={inputRef}
                  autoFocus={isListOpen}
                  {...rest}
                />
              </ListItemSearch>
            )}
            <List fullScreen>
              {selectableMenu.length === 0 ? (
                <ListItem button disabled>
                  There are no available items
                </ListItem>
              ) : (
                selectableMenu.map((option, index) => (
                  <ListItem
                    key={option.key}
                    onClick={handleOnMenuItemClick(option)}
                    selected={keyIndex === index}
                    button
                  >
                    <MuiListItemText
                      primary={option.label}
                      primaryTypographyProps={{
                        color: option.primary ? "primary" : "inherit",
                      }}
                    />
                    {checklist && (
                      <IconButton edge="end" disabled>
                        {existsInSelectedValueArray(option) ? (
                          <IconCheck fill={theme.palette.primary.main} />
                        ) : null}
                      </IconButton>
                    )}
                  </ListItem>
                ))
              )}
            </List>
          </MuiBox>
        </MuiDialog>
      </MuiFormControl>
    </MuiClickAwayListener>
  );
}

export type SelectOptionValueType = string | number;

export interface SelectOption {
  key: any;
  label: string;
  value: SelectOptionValueType;
  description?: string;
  primary?: boolean;
}
