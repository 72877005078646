import React from "react";
import styled from "styled-components";
import { TextField as MuiTextField } from "@material-ui/core";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";

const INPUT_ROOT = "input-root";
const INPUT_BASE = "input-base";
const INPUT_NOTCHED_OUTLINE = "input-notched-outline";
const INPUT_ERROR = "input-error";
const INPUT_FOCUSED = "input-focused";
const INPUT_MULTILINE = "input-multiline";
const INPUT_ADORN_END = "input-adorn-end";
const INPUT_ADORN_START = "input-adorn-start";
const HELPER_TEXT_ROOT = "helper-text-root";
const HELPER_TEXT_ERROR = "helper-text-error";

const StyledTextField = styled((props: MuiTextFieldProps) => (
  <MuiTextField
    // pass down TextField props
    {...props}
    // always set variant as "outlined"
    variant="outlined"
    // supply props that should be applied to the underlying input component
    InputProps={{
      ...props.InputProps,
      classes: {
        ...(props.InputProps || {}).classes,
        root: INPUT_ROOT,
        input: INPUT_BASE,
        notchedOutline: INPUT_NOTCHED_OUTLINE,
        error: INPUT_ERROR,
        focused: INPUT_FOCUSED,
        multiline: INPUT_MULTILINE,
        adornedEnd: INPUT_ADORN_END,
        adornedStart: INPUT_ADORN_START,
      },
    }}
    FormHelperTextProps={{
      classes: { root: HELPER_TEXT_ROOT, error: HELPER_TEXT_ERROR },
    }}
  />
))`
  &&& {
    // target deep components
    .${INPUT_NOTCHED_OUTLINE} {
      border: 1px solid #eeeeee;
      border-color: #eeeeee;
      transition: border-color 0.1s ease-out;
    }

    .${INPUT_BASE} {
      padding: 16px 16px;
      height: 24px;
      // Disable Auto Zoom in Input “Text” tag - Safari on iPhone
      // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
    }

    && .${INPUT_ROOT}:hover .${INPUT_NOTCHED_OUTLINE} {
      border-color: #eeeeee;
    }

    && .${INPUT_FOCUSED} .${INPUT_NOTCHED_OUTLINE} {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    && .${INPUT_ERROR} .${INPUT_NOTCHED_OUTLINE} {
      border-color: #ff3333;
    }

    .${HELPER_TEXT_ROOT} {
      font-size: 12px;
      margin: 10px 0 0;
    }

    .${HELPER_TEXT_ERROR} {
      color: #ff3333;
    }

    .${INPUT_MULTILINE} {
      padding: 0;
      display: flex;

      // Add rounded style in multiline only
      .${INPUT_BASE} {
        padding: 13px 16px;
      }
      .${INPUT_NOTCHED_OUTLINE} {
        border-radius: 10px;
        border: 1px solid #eeeeee;
      }
    }

    .${INPUT_ADORN_END} {
      padding-right: 16.8px;
    }

    .${INPUT_ADORN_START} .${INPUT_BASE} {
      padding-left: 0;
    }
  }
`;

export default StyledTextField;

export const TextFieldStandard = styled(StyledTextField)`
  &&&& {
    margin-bottom: 0;
    .${INPUT_NOTCHED_OUTLINE} {
      border: none;
      border-bottom: 1px solid #eee;
      border-radius: 0;
    }
    .${INPUT_BASE} {
      padding: 14px 0;
    }
  }
`;
