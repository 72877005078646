import * as React from "react";
import styled from "styled-components";

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from "@material-ui/core/IconButton";

interface IconButtonProps extends MuiIconButtonProps {
  hoverBgColor?: string;
}

const IconButton = styled(({ hoverBgColor, ...rest }: IconButtonProps) => (
  <MuiIconButton
    {...rest}
    disableTouchRipple
    disableRipple
    disableFocusRipple
  />
))`
  &&& {
    &:hover {
      background-color: ${({ theme, hoverBgColor }) =>
        hoverBgColor ?? theme.palette.blueGrey[200]};
    }
  }
`;

export default IconButton;
